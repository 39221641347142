const cols =
  "the_geom_webmercator, geogcode, geogname, lad23cd, " +
  "ah4ahah_pct, ah4r_pct, ah4h_pct, ah4g_pct, ah4e_pct, ah4ffood_pct, ah4pubs_pct, " +
  "ah4gamb_pct, ah4tob_pct, ah4gp_pct, ah4dent_pct, ah4phar_pct, ah4hosp_pct, " +
  "ah4leis_pct, ah4gpas_pct, ah4blue_pct, ah4no2_pct, ah4pm10_pct, ah4so2_pct";

const options = [
  {
    id: 0,
    name: "Decile",
    conversion: function(num) {
      return Math.ceil(num / 10);
    }
  },
  {
    id: 1,
    name: "Quartile",
    conversion: function(num) {
      return Math.ceil(num / 4);
    }
  },
  {
    id: 2,
    name: "Percentile",
    conversion: function(num) {
      return num;
    }
  }
];

const legendStops = [
  {
    id: 0,
    stops: [
      { value: 0, colour: "#4575B4", name: "Best performing decile" },
      { value: 11, colour: "#7397B6", name: "2nd" },
      { value: 21, colour: "#A2BAB9", name: "3rd" },
      { value: 31, colour: "#D0DCBC", name: "4th" },
      { value: 41, colour: "#FFFFBF", name: "5th" },
      { value: 51, colour: "#FCE1A6", name: "6th" },
      { value: 61, colour: "#FAC48D", name: "7th" },
      { value: 71, colour: "#F8A774", name: "8th" },
      { value: 81, colour: "#F68A5B", name: "9th" },
      { value: 91, colour: "#F46D43", name: "Worst performing decile" }
    ]
  },
  {
    id: 0,
    stops: [
      { value: 0, colour: "#4575B4", name: "Best performing quartile" },
      { value: 25, colour: "#A2BAB9", name: "2nd" },
      { value: 50, colour: "#FCE1A6", name: "3rd" },
      { value: 75, colour: "#F46D43", name: "Worst performing quartile" }
    ]
  },
  {
    id: 3,
    stops: [
      { value: 0, colour: "#4575B4", name: "Best Performing Percentiles" },
      { value: 11, colour: "#7397B6", name: "11 - 20 " },
      { value: 21, colour: "#A2BAB9", name: "21 - 30 " },
      { value: 31, colour: "#D0DCBC", name: "31 - 40 " },
      { value: 41, colour: "#FFFFBF", name: "41 - 50 " },
      { value: 51, colour: "#FCE1A6", name: "51 - 60 " },
      { value: 61, colour: "#FAC48D", name: "61 - 70 " },
      { value: 71, colour: "#F8A774", name: "71 - 80 " },
      { value: 81, colour: "#F68A5B", name: "81 - 90 " },
      { value: 91, colour: "#F46D43", name: "Worst Performing Percentiles" }
    ]
  }
];

export const AHAH = {
  title: "Access to Healthy Assets & Hazards",
  description:
    "A multi-dimensional index developed by the CDRC for Great Britain measuring how ‘healthy’ neighbourhoods are.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/access-healthy-assets-hazards-ahah",
  storyLinks: [
    {
      link: "https://data.cdrc.ac.uk/stories/usingahah",
      title:
        "Using Access to Health Assets and Hazards (AHAH) to understand how 'healthy' places are"
    }
  ],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM ppfi2 where ah4ahah_pct is not null",
  buildingSql:
    "SELECT " + cols + " FROM ppfi2_vmd23 where ah4ahah_pct is not null",
  detailUrbanSql:
    "SELECT " +
    cols +
    " FROM ppfi2_bua22 where ah4ahah_pct is not null",
  urbanSql:
    "SELECT " + cols + " FROM ppfi2_bua22 where ah4ahah_pct is not null",
  defaultLayers: "1111000",
  extent: 10,
  popup: true,
  popupType: "custom",
  popupFeatureConfig: {
    sections: [
      {
        id: 0,
        title: null,
        headers: ["Field", "Value"],
        rows: [
          {
            name: "AHAH",
            field: "ah4ahah_pct",
            format: "number",
            decimalPlaces: 0
          },
          {
            name: "Retail Domain",
            field: "ah4r_pct",
            format: "number",
            decimalPlaces: 0
          },
          {
            name: "Health Domain",
            field: "ah4h_pct",
            format: "number",
            decimalPlaces: 0
          },
          {
            name: "Blue/Green Space Domain",
            field: "ah4g_pct",
            format: "number",
            decimalPlaces: 0
          },
          {
            name: "Air Quality Domain",
            field: "ah4e_pct",
            format: "number",
            decimalPlaces: 0
          }
        ]
      }
    ]
  },
  popupGeographyConfig: {
    sections: [
      {
        id: 0,
        title: null,
        headers: ["AHAH", "Percentage By Count"],
        rows: [
          {
            id: 1,
            name: "Best performing Decile",
            field: "ah4decile_1",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 2,
            name: "2nd",
            field: "ah4decile_2",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 3,
            name: "3rd",
            field: "ah4decile_3",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 4,
            name: "4th",
            field: "ah4decile_4",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 5,
            name: "5th",
            field: "ah4decile_5",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 6,
            name: "6th",
            field: "ah4decile_6",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 7,
            name: "7th",
            field: "ah4decile_7",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 8,
            name: "8th",
            field: "ah4decile_8",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 9,
            name: "9th",
            field: "ah4decile_9",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 10,
            name: "Worst performing Decile",
            field: "ah4decile_10",
            format: "percent",
            decimalPlaces: 0
          }
        ]
      }
    ]
  },
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    sum(case when d.ah4ahah_pct <=10 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_1,
    sum(case when d.ah4ahah_pct <=20 and d.ah4ahah_pct >10 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_2,
    sum(case when d.ah4ahah_pct <=30 and d.ah4ahah_pct >20 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_3,
    sum(case when d.ah4ahah_pct <=40 and d.ah4ahah_pct >30 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_4,
    sum(case when d.ah4ahah_pct <=50 and d.ah4ahah_pct >40 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_5,
    sum(case when d.ah4ahah_pct <=60 and d.ah4ahah_pct >50 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_6,
    sum(case when d.ah4ahah_pct <=70 and d.ah4ahah_pct >60 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_7,
    sum(case when d.ah4ahah_pct <=80 and d.ah4ahah_pct >70 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_8,
    sum(case when d.ah4ahah_pct <=90 and d.ah4ahah_pct >80 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_9,
    sum(case when d.ah4ahah_pct >90 then 1 else 0 end) / cast(count(*)as numeric) as ah4decile_10
  FROM oliverobrien.lsoa11_uk d

  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  idField: "geogcode",
  geogNameField: "geogname",
  ladCodeField: "lad23cd",
  hoverFieldMetricMode: "MetricOnly",
  metrics: [
    {
      id: 0,
      name: "AHAH",
      field: "ah4ahah_pct",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [1, 2, 3, 4]
    },
    {
      id: 1,
      name: "Retail Domain",
      field: "ah4r_pct",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [5, 6, 7, 8, 9]
    },
    {
      id: 2,
      name: "Health Domain",
      field: "ah4h_pct",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [10, 11, 12, 13, 14]
    },
    {
      id: 3,
      name: "Blue/Green Space Domain",
      field: "ah4g_pct",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [15, 16]
    },
    {
      id: 4,
      name: "Air Quality Domain",
      field: "ah4e_pct",
      legendOptions: options,
      legendStops: legendStops,
      additionalMetrics: [17, 18, 19]
    },
    {
      id: 5,
      name: "Fast Food Accessibility",
      field: "ah4ffood_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 6,
      name: "Pub/bar/nightclub Accessibility",
      field: "ah4pubs_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 7,
      name: "Gambling Outlet Accessibility",
      field: "ah4gamb_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 8,
      name: "Tobacconist Accessibility",
      field: "ah4tob_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 9,
      name: "GP practice Accessibility",
      field: "ah4gp_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 10,
      name: "Dentist Accessibility",
      field: "ah4dent_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 11,
      name: "Pharmacy Accessibility",
      field: "ah4phar_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 12,
      name: "Hospital Accessibility",
      field: "ah4hosp_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 13,
      name: "Leisure Service Accessibility",
      field: "ah4leis_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 14,
      name: "Green Space (Passive) Accessibility",
      field: "ah4gpas_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 15,
      name: "Blue Space Accessibility",
      field: "ah4blue_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 16,
      name: "Nitrogen Dioxide (NO&#8322;) Level",
      field: "ah4no2_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 17,
      name: "Particulate Matter (PM10) Level",
      field: "ah4pm10_pct",
      legendOptions: options,
      legendStops: legendStops
    },
    {
      id: 18,
      name: "Sulphur Dioxide (SO&#8322;) Level",
      field: "ah4so2_pct",
      legendOptions: options,
      legendStops: legendStops
    }
  ]
};
